/**
 * Basic typography style for copy text
 */

html {
  font-weight: $font-weight-base;
}

body {
  text-align: left;
  color: $text-color;
  //font: normal 125% / 1.4 $text-font-stack;
  font-family: $text-font-stack;
  line-height: $line-height-base;

  font-size: $font-size-base;

  //@include media('>medium') {
  //  font-size: $font-size-lg;
  //}
}

h1, h2 {
  //font-size: $h1-font-size-small;
    //font-size: $h1-font-size-small;
    //font-size: 4vw;
  font-weight: $font-weight-light;
  color: $section-header-color;
}

h1 {
  $header-left-width: 57%;
  $header-right-width: 43%;
  $min-viewport: map_get($breakpoints-px,'medium');
  $max-viewport: $container-width-px;

  //$min-vw: $min-viewport - ($min-viewport/100%)*($container-padding) - ($min-viewport/100) * 9;
  $min-vw: $min-viewport;
  //$max-vw: $max-viewport - ($max-viewport/100%)*($container-padding) - ($max-viewport/100) * 9;
  $max-vw: $max-viewport;
  //$min-vw: $min-viewport + ($min-viewport/100%)*($header-left-width+$container-padding);
  //$max-vw: $max-viewport + ($max-viewport/100%)*($header-left-width+$container-padding);


  //@include fluid-type-rem($min-vw, $max-vw, $h1-font-size-medium, $h1-font-size-large, $min-viewport, $max-viewport);

/*
  @include scut-rem-fallback($font-size-base-px * 0.875);

  @include media('>x-small') {
    @include scut-rem-fallback($font-size-base-px);
    font-size: 4vw;
  }

  @include media('>medium') {
    //font-size: $h1-font-size-large;
    @include scut-rem-fallback($h1-font-size-medium);
    font-size: 4vw;
  }
  //@include media('>medium'){
  //  font-size: $h1-font-size-medium;
  //}
  @include media('>x-large'){
    @include scut-rem-fallback($h1-font-size-large);
    //font-size: $h1-font-size-large;
  }*/

  @include fluid-type-rem(map_get($breakpoints-px,'x-small'),map_get($breakpoints-px,'x-large'),$font-size-base-px * 0.875px,$h1-font-size-large);
}


h2, .impressum h1 {
  /*@include scut-rem-fallback($h1-font-size-small);

  @include media('>medium') {
    //font-size: $h1-font-size-large;
    font-size: 4vw;
  }
  //@include media('>medium'){
  //  font-size: $h1-font-size-medium;
  //}
  @include media('>x-large'){
    font-size: $h1-font-size-large;
  }*/

  $header-left-width: 57%;
  $header-right-width: 43%;
  $min-viewport: map_get($breakpoints-px,'small');
  $max-viewport: map_get($breakpoints-px,'medium');

  //$min-vw: $min-viewport - ($min-viewport/100%)*($container-padding) - ($min-viewport/100) * 9;
  $min-vw: $min-viewport;
  //$max-vw: $max-viewport - ($max-viewport/100%)*($container-padding) - ($max-viewport/100) * 9;
  $max-vw: $max-viewport;

  @include fluid-type-rem($min-vw, $max-vw, $h1-font-size-small, $h1-font-size-large);

}
h3 {
  font-size: rem($font-size-base-px);
  //font-size: $font-size-base;
  font-weight: $font-weight-normal;
  margin: 0;
  letter-spacing: $letter-spacing;
}

header,
p,
ul,
ol,
pre,
table,
blockquote,
address{
  margin: 0;
}

//
//blockquote h2 {
//  font-family: $quote-font-stack;
//  font-weight: $font-weight-bold;
//}



section h2 {
  span {
    //text-indent: 1.4em;
    //display: inline-block;
  }
  &, span {
    text-indent: -1.4em;
    display: inline-block;
    padding-left: 1.4em;
  }
}

a[href^='mailto:'] span{
  white-space: nowrap;

}
