/*
* Scut, a collection of Sass utilities
* to ease and improve our implementations of common style-code patterns.
* v1.4.0
* Docs at http://davidtheclark.github.io/scut
*/
/*

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
//$grid-gutter: 30px !default;
//$grid-gutter: ($sgl-base-line-height * $sgl-base-font-size * 1px)*4;
$grid-gutter-width: 30px;
$half-grid-gutter: $grid-gutter-width / 2 !default;

//$grid-column-width: ($max-width - $grid-gutter * $grid-columns - $grid-gutter * 2.5) / $grid-columns;
//$grid-column-width: $container-width / strip-unit($grid-columns + $grid-gutter * 2);
$grid-column-width: ($container-width / $grid-columns + $grid-gutter-width * 2/12) ;
//$grid-max-width: $content-max-width !default;

// Susy
// ===============
//$base-line-height: 1.25;


!* Changing Susy default global settings *!
$susy: (
  !* Tells Susy to use 12 columns *!
        columns: $grid-columns,
  !* Tells Susy that the container has a max-width of 1120px *!
  //container: 1120px,
        container: scut-em($container-width),

        column-width: $grid-column-width, // it really doesn't matter what number this is...

        //gutters: $grid-gutter-width / $grid-column-width,
        gutters: 1,
        //gutters: 20px / 120px,
        gutter-position: inside-static,

  !* Tells Susy to use border-box *!
        global-box-sizing: border-box,

        use-custom: (rem: true),

);

!* Makes border-box properties *!
//@include border-box-sizing;
*/
/**
 * Default rem baseline.
 *
 * @type Number
 */
/**
 * Transform a value into rem.
 *
 * @param {Number} $value
 * @param {Number} $baseline ($guss-rem-baseline)
 *
 * @requires {variable} guss-rem-baseline
 *
 * @return {List | Number}
 */
/**
 * Output `rem` units with `px` fallback.
 *
 * @link http://sassmeister.com/gist/7451284 Usage
 *
 * @param {Map} $properties
 *
 * @requires {function} rem
 */
/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */
/*!
Typeplate : Starter Kit
URL ........... http://typeplate.com
Version ....... 3.0.2
Github ........ https://github.com/typeplate/starter-kit
Authors ....... Dennis Gaebel (@gryghostvisuals) & Zachary Kain (@zakkain)
License ....... Creative Commmons Attribution 3.0
License URL ... https://github.com/typeplate/starter-kit/blob/master/license.txt
*/
/*,
 '~sassy-gridlover/sassy-gridlover/sassy-gridlover' */
/*,
  '~scut/dist/scut'*/
/*'~responsive-nav/responsive-nav'*/
/**
 * Set up a decent box model on the root element
 */
@import url(~typeface-roboto-slab/index.css);
@import url(~typeface-gentium-basic/index.css);
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit; }

@-ms-viewport {
  width: device-width; }

/**
 * Remove default margin.
 */
body {
  margin: 0; }

/* HTML5 display definitions
   ========================================================================== */
/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
  *display: inline;
  *zoom: 1; }

/**
 * Add the correct display in iOS 4-7.
 */
audio:not([controls]) {
  display: none;
  height: 0; }

/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */
[hidden],
template {
  display: none; }

/* Links
   ========================================================================== */
/**
 * Remove the gray background color from active links in IE 10.
 */
/**
 * Basic styles for links
 */
a {
  color: #0072BC;
  background-color: transparent;
  text-decoration: underline; }
  a:hover, a:active, a:focus {
    color: #151716;
    outline: 0; }

/* Text-level semantics
   ========================================================================== */
/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */
abbr[title] {
  border-bottom: 1px dotted; }

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */
b,
strong {
  font-weight: bold; }

/**
 * Address styling not present in Safari and Chrome.
 */
dfn {
  font-style: italic; }

/**
 * Addresses styling not present in IE 8/9.
 */
mark {
  background: #ff0;
  color: #000; }

/**
 * Addresses margins set differently in IE 6/7.
 */
p,
pre {
  *margin: 1em 0; }

/*
 * Addresses CSS quotes not supported in IE 6/7.
 */
q {
  *quotes: none; }

/*
 * Addresses `quotes` property not supported in Safari 4.
 */
q:before,
q:after {
  content: '';
  content: none; }

/**
 * Address inconsistent and variable font size in all browsers.
 */
small {
  font-size: 80%; }

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */
sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

/* Embedded content
   ========================================================================== */
/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */
img {
  border: 0;
  *-ms-interpolation-mode: bicubic;
  /* 2 */ }

/**
 * Correct overflow not hidden in IE 9/10/11.
 */
svg:not(:root) {
  overflow: hidden; }

/* Forms
   ========================================================================== */
/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */
/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; }

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */
button {
  overflow: visible; }

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */
button,
select {
  text-transform: none; }

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
  *overflow: visible;
  /* 4 */ }

/**
 * Re-set default cursor for disabled elements.
 */
button[disabled],
html input[disabled] {
  cursor: default; }

/**
 * Remove inner padding and border in Firefox 4+.
 */
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */
textarea {
  margin: 0;
  overflow: auto; }

/* Tables
   ========================================================================== */
/**
 * Remove most spacing between table cells.
 */
table {
  border-collapse: collapse; }

html, body {
  min-height: 100%; }

body {
  border-top: 1em solid #003F6B;
  padding-top: 1em;
  background-color: #EAEFED;
  /* Fallback Color */
  background-image: linear-gradient(to bottom, #D1D8DD, #EAEFED);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#D1D8DD', EndColorStr='#EAEFED');
  background-repeat: no-repeat; }

main {
  overflow: hidden; }

/*p a {
  color: #222;
  background-color: #a8af67;
  text-decoration: none;$brand-color
  padding: 0 0.25em;
  font-weight: 600;
  font-size: 0.9em;
  &::before {
    content: "\2192";
    padding-right: 2px;
  }
}*/
figure {
  margin: 0; }

address {
  font-style: normal;
  display: inline-block;
  letter-spacing: 0.02em; }

/*
 * A better looking default horizontal rule
 */
hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 0.5em solid #003F6B;
  margin: 1em 0;
  padding: 0;
  width: 5em; }

section .container > *, header .left > * {
  /* @include scut-rem-fallback(15, margin-left);
     margin-left: 5vw;

     @include media('>small') {
       margin-left: 6rem;
       margin-left: 9vw;
     }
     @include media('>x-large') {
       margin-left: 6rem;
       //margin-left: 20%;
     }*/
  margin-left: 0.9375rem; }
  @media screen and (min-width: 20em) {
    section .container > *, header .left > * {
      margin-left: 51.2px;
      margin-left: calc(0.9375rem + 81 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 75em) {
    section .container > *, header .left > * {
      margin-left: 6rem; } }

/**
 * Basic typography style for copy text
 */
html {
  font-weight: 300; }

body {
  text-align: left;
  color: #151716;
  font-family: "Roboto Slab", "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
  line-height: 1.25;
  font-size: 1rem; }

h1, h2 {
  font-weight: 300;
  color: #003F6B; }

h1 {
  /*
  @include scut-rem-fallback($font-size-base-px * 0.875);

  @include media('>x-small') {
    @include scut-rem-fallback($font-size-base-px);
    font-size: 4vw;
  }

  @include media('>medium') {
    //font-size: $h1-font-size-large;
    @include scut-rem-fallback($h1-font-size-medium);
    font-size: 4vw;
  }
  //@include media('>medium'){
  //  font-size: $h1-font-size-medium;
  //}
  @include media('>x-large'){
    @include scut-rem-fallback($h1-font-size-large);
    //font-size: $h1-font-size-large;
  }*/
  font-size: 0.875rem; }
  @media screen and (min-width: 20em) {
    h1 {
      font-size: 27.42857px;
      font-size: calc(0.875rem + 34 * ((100vw - 320px) / 880)); } }
  @media screen and (min-width: 75em) {
    h1 {
      font-size: 3rem; } }

h2, .impressum h1 {
  /*@include scut-rem-fallback($h1-font-size-small);

  @include media('>medium') {
    //font-size: $h1-font-size-large;
    font-size: 4vw;
  }
  //@include media('>medium'){
  //  font-size: $h1-font-size-medium;
  //}
  @include media('>x-large'){
    font-size: $h1-font-size-large;
  }*/
  font-size: 1.3rem; }
  @media screen and (min-width: 36em) {
    h2, .impressum h1 {
      font-size: 18.46154px;
      font-size: calc(1.3rem + 27.2 * ((100vw - 576px) / 192)); } }
  @media screen and (min-width: 48em) {
    h2, .impressum h1 {
      font-size: 3rem; } }

h3 {
  font-size: 16;
  font-weight: 400;
  margin: 0;
  letter-spacing: 0.02em; }

header,
p,
ul,
ol,
pre,
table,
blockquote,
address {
  margin: 0; }

section h2, section h2 span {
  text-indent: -1.4em;
  display: inline-block;
  padding-left: 1.4em; }

a[href^='mailto:'] span {
  white-space: nowrap; }

/**
 * Clear inner floats
 */
.clearfix::after {
  clear: both;
  content: '';
  display: table; }

/**
 * Main content containers
 * 1. Make the container full-width with a maximum width
 * 2. Center it in the viewport
 * 3. Leave some space on the edges, especially valuable on small screens
 */
/**
 * Hide text while making it readable for screen readers
 * 1. Needed in WebKit-based browsers because of an implementation bug;
 *    See: https://code.google.com/p/chromium/issues/detail?id=457146
 */
.hide-text {
  overflow: hidden;
  padding: 0;
  /* 1 */
  text-indent: 101%;
  white-space: nowrap; }

/**
 * Hide element while making it readable for screen readers
 * Shamelessly borrowed from HTML5Boilerplate:
 * https://github.com/h5bp/html5-boilerplate/blob/master/src/css/main.css#L119-L133
 */
.visually-hidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.pull-right {
  float: right; }
  .box .pull-right {
    /**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */ }
    .box .pull-right:before, .box .pull-right:after {
      content: '';
      display: block; }
    .box .pull-right:after {
      clear: both; }

.pull-left {
  float: left; }

.container {
  width: 100%;
  padding-right: 2%;
  padding-left: 2%;
  margin-right: auto;
  margin-left: auto;
  max-width: 71.25em; }

section {
  padding: 3em 0; }

.inline-parent {
  font-size: 0;
  display: inline-block;
  width: 100%; }

header h1 {
  position: relative;
  line-height: 1.1;
  margin-bottom: 2em; }
  header h1 .title {
    display: inline-block;
    width: 100%;
    font-weight: 400;
    font-size: 0.6em;
    text-indent: -2em;
    margin-bottom: 0.5em; }
  header h1 .first-name,
  header h1 .last-name {
    color: #151716; }
  header h1 .last-name {
    font-size: 1.666em; }
  header h1::after {
    content: '';
    background-color: #003F6B;
    height: 0.17em;
    width: 1.7em;
    position: absolute;
    bottom: -1em;
    left: 0; }

header img {
  height: auto;
  width: 100%;
  border-radius: 0.1875rem; }

header .left,
header .right {
  display: inline-block;
  width: 100%;
  vertical-align: bottom;
  font-size: 1rem;
  box-sizing: inherit; }

header .left {
  width: 57%; }

header .right {
  width: 43%;
  padding-left: 2%; }

header address {
  font-size: 0.75em; }
  @media (min-width: 45.18647em) {
    header address {
      font-size: 1em;
      font-size: 1.7vw; } }
  @media (min-width: 58.36294em) {
    header address {
      font-size: 1.25em;
      font-size: 1.7vw; } }
  @media (min-width: 70.59824em) {
    header address {
      font-size: 1.25em; } }

body > footer {
  font-size: 0.8125rem;
  text-align: center;
  padding-top: 6em;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 3em; }
  body > footer p {
    padding-top: 0.5em;
    border-top: 0.0625em solid #003F6B;
    margin-left: auto;
    margin-right: auto;
    max-width: 82%; }

table {
  font-size: 0.875rem; }
  @media screen and (min-width: 36em) {
    table {
      font-size: 10.28571px;
      font-size: calc(0.875rem + 4 * ((100vw - 576px) / 192)); } }
  @media screen and (min-width: 48em) {
    table {
      font-size: 1.125rem; } }

tr:first-of-type td:nth-of-type(3)::before {
  top: 0.5em; }

tr:last-of-type td:nth-of-type(3)::before {
  height: .5em; }

tr td {
  padding-bottom: 1em;
  position: relative;
  vertical-align: top; }
  tr td:first-of-type {
    text-align: right;
    padding-right: 0.9em;
    color: #6C8FB2;
    font-weight: 400;
    display: block; }
    @media (min-width: 33.89235em) {
      tr td:first-of-type {
        width: 8.2em;
        padding-right: 1em; } }
  tr td.dot {
    position: relative;
    width: 0.625em;
    padding: 0;
    z-index: 1; }
    tr td.dot::after {
      content: '';
      width: 0.625em;
      height: 0.625em;
      position: absolute;
      top: 0.4em;
      background-color: #A4B8D0;
      border-radius: 50%; }
  tr td:nth-of-type(3) {
    color: #171917;
    padding-left: 0.9em;
    letter-spacing: 0.02em; }
    @media (min-width: 33.89235em) {
      tr td:nth-of-type(3) {
        padding-left: 1em; } }
    tr td:nth-of-type(3)::before {
      content: '';
      margin-left: -0.34375em;
      border-left: 0.0625em solid #003F6B;
      position: absolute;
      left: 0;
      height: 100%; }

#quote {
  font-family: "Gentium Basic", Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
  font-weight: 700;
  background-color: #D1D8DD; }
  #quote .container {
    /**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
    padding-top: 1em;
    padding-bottom: 1em; }
    #quote .container:before, #quote .container:after {
      content: '';
      display: block; }
    #quote .container:after {
      clear: both; }
  #quote img {
    float: left;
    width: 20%;
    margin-left: 4%; }
  #quote .pull-quote {
    float: left;
    position: relative;
    padding: 1em;
    margin: .75em 0 0 5%;
    font-size: 1rem;
    /*font-size: 1.25em;
    font-size: 4vw;

    @include media('>small') {
      font-size: 1.4em;
    }

    @include media('>medium') {
      font-size: 2em;
      font-size: 4vw;
    }

    @include media('>large') {
      font-size: 2.3em;
    }

    @include media('>x-large') {
      font-size: 2.7em;
    }*/ }
    @media screen and (min-width: 20em) {
      #quote .pull-quote {
        font-size: 24px;
        font-size: calc(1rem + 32 * ((100vw - 320px) / 672)); } }
    @media screen and (min-width: 62em) {
      #quote .pull-quote {
        font-size: 3rem; } }
    #quote .pull-quote:before, #quote .pull-quote:after {
      font-family: "Gentium Basic", Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;
      font-size: 2em;
      color: #C5C6C6;
      position: absolute;
      height: 1em;
      font-weight: 400;
      font-style: normal;
      -webkit-transform: scale(1, 1.2);
          -ms-transform: scale(1, 1.2);
              transform: scale(1, 1.2); }
    #quote .pull-quote:before {
      content: '\201D';
      bottom: 0;
      left: 0; }
    #quote .pull-quote:after {
      content: '\201C';
      top: 0;
      right: 0; }
  #quote h2 {
    color: #6F6F6E;
    font-style: italic;
    font-weight: inherit;
    font-size: 1em;
    text-indent: 0;
    padding: 0;
    margin: 0; }
  #quote cite {
    float: right;
    margin-right: -1.4em;
    color: #6F6F6E;
    font-family: "Roboto Slab", "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;
    font-style: normal;
    font-size: 0.5em;
    font-weight: 400;
    display: block; }

#publication {
  background-color: #D1D8DD; }
  #publication h2 {
    display: block; }
  #publication .container {
    position: relative;
    /**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */ }
    #publication .container:before, #publication .container:after {
      content: '';
      display: block; }
    #publication .container:after {
      clear: both; }
  #publication table {
    float: left;
    width: 57%; }
  #publication .img-wrapper {
    float: left;
    width: 24%;
    margin-left: 0; }
    #publication .img-wrapper img {
      width: 267%;
      margin-right: -15%;
      margin-top: -60%; }
    @media (max-width: 33.87235em) {
      #publication .img-wrapper {
        width: 100%; }
        #publication .img-wrapper img {
          width: 100%;
          margin: 0; } }

a[href^='tel:'] {
  background-color: transparent;
  font-size: 1em;
  padding: 0; }
  a[href^='tel:']:before {
    content: '';
    padding: 0; }
