
$body-bg: #EAEFED !default;

//TODO: anderen Namen?
//$container-bg: inherit !default;

/// Copy text color
/// @type Color
//$text-color: rgb(34, 34, 34) !default;
$text-color: #151716 !default;

$section-header-color: #003F6B !default;


$section-bg-color: #D1D8DD !default;

$link-color: #0072BC !default;

$time-line-year-color: #6C8FB2 !default;

$time-line-dot-color: #A4B8D0 !default;

$time-line-line-color: #003F6B !default;

$time-line-text-color: #171917 !default;

$quotation-mark-color: #C5C6C6;

$quote-color: #6F6F6E;
