/**
 * Default rem baseline.
 *
 * @type Number
 */
$guss-rem-baseline: 16px !default;
$rem-fallback: false !default;
$px-only: false !default;

/**
 * Transform a value into rem.
 *
 * @param {Number} $value
 * @param {Number} $baseline ($guss-rem-baseline)
 *
 * @requires {variable} guss-rem-baseline
 *
 * @return {List | Number}
 */
@function rem($value, $baseline: $guss-rem-baseline) {
  @if $value == 0 {
    @return 0; // 0rem -> 0
  }

  @if type-of($value) == list {
    $result: ();

    @each $e in $value {
      $result: append($result, rem($e, $baseline));
    }

    @return $result;
  } @else {
    @return if(
      type-of($value) == number and unit($value) == px,
      $value / $baseline * 1rem,
      $value
    );
  }
}

/**
 * Output `rem` units with `px` fallback.
 *
 * @link http://sassmeister.com/gist/7451284 Usage
 *
 * @param {Map} $properties
 *
 * @requires {function} rem
 */
@mixin rem($properties) {
  @if(type_of($properties) == 'map') {
    @each $property, $value in $properties {
      @if (type-of($value) == number and $value != 0) {
        // Turn unitless values into pixels
        $value: if(unitless($value), $value * 1px, $value);
      }

      @if ($rem-fallback or $px-only) {
        #{$property}: $value;
      } @else if ($px-only == false) {
        #{$property}: rem($value);
      }
    }
  } @else {
    @if ($rem-fallback or $px-only) {
      font-size: $properties;
    } @else if ($px-only == false) {
      font-size: rem($properties);
    }
  }
}
