$ie8: false !default;

@mixin display-inline() {

  @if($ie8) {
    display: inline-block;
    *zoom:1;
    *display: inline;
    width: 100%;
  } @else {
    display: inline-block;
    width: 100%;
  }
}
