@charset "UTF-8";

/**
 * For modern browsers
 * 1. The space content is one way to avoid an Opera bug when the
 *    contenteditable attribute is included anywhere else in the document.
 *    Otherwise it causes space to appear at the top and bottom of elements
 *    that are clearfixed.
 * 2. The use of `table` rather than `block` is only necessary if using
 *    `:before` to contain the top-margins of child elements.
 */

@mixin clearfix {
  &:before,
  &:after {
    content: '';
    display: block;
  }

  &:after {
    clear: both;
  }

  /**
 * For IE 6/7 only
 * Include this rule to trigger hasLayout and contain floats.
 */
  @if ($ie8) {
    & {
      *zoom: 1;
    }
  }
}
