


/// Container's maximum width
/// @type Length
//$max-width: 1180px !default;
//$max-width: 1180px !default;
$max-width: 1200px !default;
$container-width-px: 1140px !default;
$container-width: scut-em($container-width-px) !default;
$container-padding: 2%;
//$container-padding: scut-rem(40px);

@import '../abstracts/mixins/im-to-em';
/// Breakpoints map
/// @prop {String} keys - Keys are identifiers mapped to a given length
/// @prop {Map} values - Values are actual breakpoints expressed in pixels
$breakpoints-px: (
        'x-small': 320px,
        'small': 576px,
        'medium': 768px,
        'large': 992px,
        'x-large': 1200px
);
$breakpoints: im-to-em($breakpoints-px);


/*

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 12 !default;
//$grid-gutter: 30px !default;
//$grid-gutter: ($sgl-base-line-height * $sgl-base-font-size * 1px)*4;
$grid-gutter-width: 30px;
$half-grid-gutter: $grid-gutter-width / 2 !default;

//$grid-column-width: ($max-width - $grid-gutter * $grid-columns - $grid-gutter * 2.5) / $grid-columns;
//$grid-column-width: $container-width / strip-unit($grid-columns + $grid-gutter * 2);
$grid-column-width: ($container-width / $grid-columns + $grid-gutter-width * 2/12) ;
//$grid-max-width: $content-max-width !default;

// Susy
// ===============
//$base-line-height: 1.25;


!* Changing Susy default global settings *!
$susy: (
  !* Tells Susy to use 12 columns *!
        columns: $grid-columns,
  !* Tells Susy that the container has a max-width of 1120px *!
  //container: 1120px,
        container: scut-em($container-width),

        column-width: $grid-column-width, // it really doesn't matter what number this is...

        //gutters: $grid-gutter-width / $grid-column-width,
        gutters: 1,
        //gutters: 20px / 120px,
        gutter-position: inside-static,

  !* Tells Susy to use border-box *!
        global-box-sizing: border-box,

        use-custom: (rem: true),

);

!* Makes border-box properties *!
//@include border-box-sizing;
*/



