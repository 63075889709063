a[href^='tel:'] {
  background-color: transparent;
  //&:before {
  //  content: '\260e';
  //  margin-right: 0.5em;
  //}

  &:before {
    content: '';
    padding: 0;
  }

  font-size: 1em;
  padding: 0;
}
