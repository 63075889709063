@charset "UTF-8";

//$rem-fallback: true;


// 1. Configuration and helpers
@import 'variables/variables', 'abstracts/functions', 'abstracts/mixins/guss-rem', 'abstracts/mixins';

// 2. Vendors
@import '~include-media/dist/include-media';
/*,
 '~sassy-gridlover/sassy-gridlover/sassy-gridlover' */
/*,
  '~scut/dist/scut'*/ /*'~responsive-nav/responsive-nav'*/

// 3. Base stuff
@import 'base/normalize', 'base/base', 'base/fonts', 'base/typography',
  'base/helpers';

// 4. Layout-related sections
@import 'layout/grid', 'layout/header', 'layout/footer';

// 5. Components
@import "components/timeline", "components/quote", "components/publication";

// 6. Page-specific styles
@import 'pages/home', 'pages/contact';

// 7. Themes
//@import
//  'themes/default';
