// -----------------------------------------------------------------------------
// This file contains all styles related to the footer of the site/application.
// -----------------------------------------------------------------------------

body > footer {
  @include rem(13px);
  text-align: center;
  padding-top: 6em;
  padding-right: 0;
  padding-left: 0;
  padding-bottom: 3em;

  p {
    padding-top: 0.5em;
    border-top: $border-width solid $section-header-color;

    //width: 80%;
    //margin-left: auto;
    //margin-right: auto;

    @include scut-center-block(82%);
  }
}
