//TODO: raus? nicht in Benutzung
//.wrapper {
//  //TODO: rename
//  //background-color: $container-bg;
//    max-width: $max-width; /* 1 */
//    margin-left: auto; /* 2 */
//    margin-right: auto; /* 2 */
//  //  padding-left: 20px; /* 3 */
//  //  padding-right: 20px; /* 3 */
//  //  width: 100%; /* 1 */
//}


.container {
  @include make-container();
  max-width: $container-width;
  //TODO: entfernen
  //border: 1px solid;

  //@include media('>medium') {
  //  padding: 0 $container-padding/2;
  //}
  //
  //@include media('>large') {
  //  padding: 0 $container-padding;
  //}

}

//TODO: raus und händisch?
header, section {

}

section {
  padding: 3em 0;
}
.inline-parent {
  font-size: 0;
  @include display-inline();
}

.left, .right {
  //font-size: 0;
  //display: inline-block;
  //width: 50%;
}


footer {
  //background: linear-gradient(to left, rgba(red, 0.125), rgba(red, 0.125)),
  //  rgba(white, 0.5);
  //background-clip: content-box, padding-box;
  //float: left;
  //padding-bottom: 1rem;
  //padding-top: 1rem;


  //@include span(12);

  //width: 100%;
  //padding-left: 50px;
  //padding-right: 50px;
}
