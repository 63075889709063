// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------

/**
 * Set up a decent box model on the root element
 */
html {
  box-sizing: border-box;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
}

/**
 * Make all elements from the DOM inherit from the parent box-sizing
 * Since `*` has a specificity of 0, it does not override the `html` value
 * making all elements inheriting from the root box-sizing value
 * See: https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/
 */
*,
*::before,
*::after {
  box-sizing: inherit;
}

// IE10+ doesn't honor `<meta name="viewport">` in some cases.
@at-root {
  @-ms-viewport {
    width: device-width;
  }
}


/**
 * Remove default margin.
 */
body {
  margin: 0;
}


/* HTML5 display definitions
   ========================================================================== */

/**
 * Correct `block` display not defined for any HTML5 element in IE 8/9.
 * Correct `block` display not defined for `details` or `summary` in IE 10/11
 * and Firefox.
 * Correct `block` display not defined for `main` in IE 11.
 */

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block;
}

/**
 * 1. Correct `inline-block` display not defined in IE 6/7/8/9 and Firefox 3.
 * 2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera.
 */

audio,
canvas,
progress,
video {
  display: inline-block; /* 1 */
  vertical-align: baseline; /* 2 */
  *display: inline;
  *zoom: 1;
}

/**
 * Add the correct display in iOS 4-7.
 */

audio:not([controls]) {
  display: none;
  height: 0;
}


/**
 * Address `[hidden]` styling not present in IE 8/9/10.
 * Hide the `template` element in IE 8/9/10/11, Safari, and Firefox < 22.
 */

[hidden],
template {
  display: none;
}


/* Links
   ========================================================================== */

/**
 * Remove the gray background color from active links in IE 10.
 */

/**
 * Basic styles for links
 */
a {
  color: $link-color;
  background-color: transparent;
  text-decoration: underline;
  //text-decoration: none;

  @include on-event {
    color: $text-color;
    outline: 0;
  }
}

/* Text-level semantics
   ========================================================================== */

/**
 * Address styling not present in IE 8/9/10/11, Safari, and Chrome.
 */

abbr[title] {
  border-bottom: 1px dotted;
}

/**
 * Address style set to `bolder` in Firefox 4+, Safari, and Chrome.
 */

b,
strong {
  font-weight: bold;
}

//@if $legacy_browser_support {
//  blockquote {
//    margin: 1em 40px;
//  }
//}

/**
 * Address styling not present in Safari and Chrome.
 */

dfn {
  font-style: italic;
}


/**
 * Addresses styling not present in IE 8/9.
 */

mark {
  background: #ff0;
  color: #000;
}


/**
 * Addresses margins set differently in IE 6/7.
 */

p,
pre {
  *margin: 1em 0;
}

/*
 * Addresses CSS quotes not supported in IE 6/7.
 */

q {
  *quotes: none;
}

/*
 * Addresses `quotes` property not supported in Safari 4.
 */

q:before,
q:after {
  content: '';
  content: none;
}

/**
 * Address inconsistent and variable font size in all browsers.
 */

small {
  font-size: 80%;
}

/**
 * Prevent `sub` and `sup` affecting `line-height` in all browsers.
 */

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}


/* Embedded content
   ========================================================================== */

/**
 * 1. Remove border when inside `a` element in IE 8/9/10.
 * 2. Improves image quality when scaled in IE 7.
 */

img {
  border: 0;
  *-ms-interpolation-mode: bicubic; /* 2 */
}

/**
 * Correct overflow not hidden in IE 9/10/11.
 */

svg:not(:root) {
  overflow: hidden;
}

/* Forms
   ========================================================================== */

/**
 * Known limitation: by default, Chrome and Safari on OS X allow very limited
 * styling of `select`, unless a `border` property is set.
 */

/**
 * 1. Correct color not being inherited.
 *  Known issue: affects color of disabled elements.
 * 2. Correct font properties not being inherited.
 * 3. Address margins set differently in Firefox 4+, Safari, and Chrome.
 * 4. Improves appearance and consistency in all browsers.
 */

button,
input,
optgroup,
select,
textarea {
  color: inherit; /* 1 */
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  margin: 0; // Remove the margin in Firefox and Safari
  //vertical-align: baseline; /* 3 */
  //*vertical-align: middle; /* 3 */
}

/**
 * Address `overflow` set to `hidden` in IE 8/9/10/11.
 */

button {
  overflow: visible;
}

/**
 * Address inconsistent `text-transform` inheritance for `button` and `select`.
 * All other form control elements do not inherit `text-transform` values.
 * Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
 * Correct `select` style inheritance in Firefox.
 */

button,
select {
  text-transform: none;
}

/**
 * 1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
 *  and `video` controls.
 * 2. Correct inability to style clickable `input` types in iOS.
 * 3. Improve usability and consistency of cursor style between image-type
 *  `input` and others.
 * 4. Removes inner spacing in IE 7 without affecting normal text inputs.
 *  Known issue: inner spacing remains in IE 6.
 */

button,
html input[type="button"], /* 1 */
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button; /* 2 */
  cursor: pointer; /* 3 */
  *overflow: visible;  /* 4 */
}

/**
 * Re-set default cursor for disabled elements.
 */

button[disabled],
html input[disabled] {
  cursor: default;
}

/**
 * Remove inner padding and border in Firefox 4+.
 */

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/**
 * Fix the cursor style for Chrome's increment/decrement buttons. For certain
 * `font-size` values of the `input`, it causes the cursor style of the
 * decrement button to change from `default` to `text`.
 */

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

/**
 * Remove default vertical scrollbar in IE 8/9/10/11.
 */

textarea {
  margin: 0;
  overflow: auto;
}


/* Tables
   ========================================================================== */

/**
 * Remove most spacing between table cells.
 */

table {
  border-collapse: collapse;
  //border-spacing: 0;
}



