$min-viewport: map_get($breakpoints-px,'x-small');
//$max-viewport: map_get($breakpoints-px,'medium');
$max-viewport: map_get($breakpoints-px,'large');
//$max-viewport: $container-width-px;
//
//$min-vw: $min-viewport - ($min-viewport/100%)*($container-padding) - ($min-viewport/100) * 9;
$min-vw: $min-viewport;
//$max-vw: $max-viewport - ($max-viewport/100%)*($container-padding) - ($max-viewport/100) * 9;
$max-vw: $max-viewport;



#quote {
  font-family: $quote-font-stack;
  font-weight: $font-weight-bold;
  background-color: $section-bg-color;
  .container {
    @include clearfix;
    padding-top: 1em;
    padding-bottom: 1em;
  }
  img {
    float: left;
    width: 20%;
    //margin-left: 2em;
    margin-left: 4%;
  }

  blockquote {
    //width: 64%;

  }
  .pull-quote {
    float: left;
    position: relative;
    padding: 1em;

    //margin: 0.4em 0 0 10%;
    margin: .75em 0 0 5%;


    @include fluid-type-rem($min-vw, $max-vw, 16px, 48px);

    /*font-size: 1.25em;
    font-size: 4vw;

    @include media('>small') {
      font-size: 1.4em;
    }

    @include media('>medium') {
      font-size: 2em;
      font-size: 4vw;
    }

    @include media('>large') {
      font-size: 2.3em;
    }

    @include media('>x-large') {
      font-size: 2.7em;
    }*/

    &:before,
    &:after {
      font-family: $quote-font-stack;
      font-size: 2em;
      color: $quotation-mark-color;
      position: absolute;
      //height: 1.4em;
      //height: $line-height-base * strip-unit($quote-font-size) + strip-unit();
      height: 1em;
      font-weight: $font-weight-normal;
      font-style: normal;
      transform: scale(1, 1.2);
    }

    &:before {
      content: '\201D';
      bottom: 0;
      left: 0;
    }
    &:after {
      content: '\201C';
      top: 0;
      right: 0;
    }
  }
  h2 {
    color: $quote-color;
    //display: inline-block;
    //position: relative;
    font-style: italic;
    font-weight: inherit;
    //font-size: $quote-font-size;
    font-size: 1em;
    text-indent: 0;
    //padding: 1em;
    padding: 0;
    margin: 0;
  }

  cite {
    float: right;
    //margin-right: 1.4em;
    margin-right: -1.4em;
    color: $quote-color;
    font-family: $text-font-stack;
    font-style: normal;
    font-size: 0.5em;
    font-weight: $font-weight-normal;
    display: block;
  }
}
