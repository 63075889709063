// -----------------------------------------------------------------------------
// This file contains all styles related to the header of the site/application.
// -----------------------------------------------------------------------------


$header-left-width: 57%;
$header-right-width: 43%;
$min-viewport: 320px;
$max-viewport: $container-width-px;
$min-font-size: 14px;
$max-font-size: 20px;

//left: 2% + 57% = 59% von ...
$min-vw: ($min-viewport/100%)*($header-left-width+$container-padding);
$max-vw: ($max-viewport/100%)*($header-left-width+$container-padding);


//HEADER NAME

header h1 {
  position: relative;
  line-height: 1.1;
  margin-bottom: 2em;
  .title {
    @include display-inline();
    font-weight: $font-weight-normal;
    font-size: 0.6em;
    text-indent: -2em;
    margin-bottom: 0.5em;
  }
  .first-name,
  .last-name {
    color: $text-color;
  }
  .last-name {
    font-size: 1.666em;
  }
  //address {
  //  font-size: 1rem;
  //}
  &::after {
    content: '';
    background-color: $time-line-line-color;
    height: 0.17em;
    width: 1.7em;
    position: absolute;
    bottom: -1em;
    left: 0;
  }
}

header {
  //weil container alles umschließt...
  //margin-left: -$half-grid-gutter;
  //margin-right: -$half-grid-gutter;
  //@include container;



  img {
    height: auto;
    //max-width: 100%;
    width: 100%;
    @include rem((border-radius: $border-radius));
    //TODO: vertical rhythm
    //margin-bottom: 12px;
  }

  .container {
  }

  .left,
  .right {
    @include display-inline();
    vertical-align: bottom;
    //width: 45%;
    @include rem($font-size-base-px * 1px);

    box-sizing: inherit;
    //@include media('>medium') {
    //  font-size: $font-size-lg;
    //}
  }
  .left {
    //margin-right: 5%;
    width: 57%;
  }
  .right {
    //margin-left: 5%;
    width: 43%;
    padding-left: 2%;
  }

  address {
    font-size: 0.75em;

    @include media('>medium') {
      font-size: 1em;
      font-size: 1.7vw;
    }

    @include media('>large') {
      font-size: 1.25em;
      font-size: 1.7vw;
    }

    @include media('>x-large'){
      font-size: 1.25em;
    }
    //@include fluid-type-rem($min-vw, $max-vw, $min-font-size, $max-font-size);
  }
}


