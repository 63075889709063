html, body {
  min-height:100%
}

body {
  //background-color: $body-bg;
  //background: linear-gradient(to bottom,$section-bg-color,$body-bg);
  border-top: 1em solid $section-header-color;
  padding-top: 1em;
  @include linear-gradient(to bottom,$section-bg-color,$body-bg);
  background-repeat: no-repeat;
}

main {
  overflow: hidden;
}

//.wrapper {
//  min-height: 100%;
//  height: auto;
//
//  background-color: $body-bg;
//}


/*p a {
  color: #222;
  background-color: #a8af67;
  text-decoration: none;$brand-color
  padding: 0 0.25em;
  font-weight: 600;
  font-size: 0.9em;
  &::before {
    content: "\2192";
    padding-right: 2px;
  }
}*/

//
//p,
//ul,
//ol,
//pre,
//table,
//blockquote {
//  @include sgl-margins();
//}

figure {
  margin: 0;
}

address {
  font-style: normal;
  display: inline-block;
  letter-spacing: $letter-spacing;
}

/*
 * A better looking default horizontal rule
 */

hr {
  display: block;
  height: 0;
  border: 0;
  border-top: 0.5em solid $time-line-line-color;
  margin: 1em 0;
  padding: 0;
  width: 5em;
}



//komplettes Layout hat einen margin mit vereinzelten first-line Headings nach links rausragend
section .container, header .left {

   > * {
    /* @include scut-rem-fallback(15, margin-left);
     margin-left: 5vw;

     @include media('>small') {
       margin-left: 6rem;
       margin-left: 9vw;
     }
     @include media('>x-large') {
       margin-left: 6rem;
       //margin-left: 20%;
     }*/

     @include fluid-type-rem(map_get($breakpoints-px,'x-small'),map_get($breakpoints-px,'x-large'),15px,96px, margin-left);
  }
}

