/// Regular font family
/// @type List

$text-font-stack: 'Roboto Slab', "Palatino Linotype", Palatino, Palladio, "URW Palladio L", "Book Antiqua", Baskerville, "Bookman Old Style", "Bitstream Charter", "Nimbus Roman No9 L", Garamond, "Apple Garamond", "ITC Garamond Narrow", "New Century Schoolbook", "Century Schoolbook", "Century Schoolbook L", Georgia, serif;

$quote-font-stack: 'Gentium Basic', Corbel, "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", "DejaVu Sans", "Bitstream Vera Sans", "Liberation Sans", Verdana, "Verdana Ref", sans-serif;

$font-size-base-px: 16 !default;
$font-size-base: 1rem !default;
$font-size-lg: ($font-size-base * 1.25) !default;
$font-size-sm: ($font-size-base * 0.875) !default;

$font-weight-thin: 100 !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 700 !default;

$font-weight-base: $font-weight-light !default;

$line-height-base: 1.25 !default;

//$h1-font-size-large: $font-size-base * 3 !default;
$h1-font-size-large: $font-size-base-px * 3 *1px!default;
$h1-font-size-medium: $font-size-base-px * 2.5 *1px!default;
$h1-font-size-small: $font-size-base-px * 1.3 *1px!default;

$letter-spacing: .02em;


//rfs
// Configuration

// Minimum font size
//$rfs-minimum-font-size: 1rem !default;
//$rfs-font-size-unit: rem !default;
//
//// Breakpoint at where font-size starts decreasing if screen width is smaller
//$rfs-breakpoint: 1200px !default;
//$rfs-breakpoint-unit: px !default;
//
//// Resize font-size based on screen height and width
//$rfs-two-dimensional: false !default;
//
//// Factor of decrease
//$rfs-factor: 5 !default;
//
//// Generate enable or disable classes. Possibilities: false, "enable" or "disable"
//$rfs-class: false !default;
//
//// 1 rem = $rfs-rem-value px
//$rfs-rem-value: 16 !default;
//
//// Safari iframe resize bug: https://github.com/project-rfs/rfs/issues/14
//$rfs-safari-iframe-resize-bug-fix: false !default;
//
//// Disable RFS by setting $enable-responsive-font-sizes to false
//$enable-responsive-font-sizes: true !default;
@import "~rfs/scss/rfs";
