
$header-left-width: 57%;
$header-right-width: 43%;
$min-viewport: map_get($breakpoints-px,'small');
$max-viewport: map_get($breakpoints-px,'medium');
$min-font-size: 14px;
$max-font-size: 18px;

//$min-vw: $min-viewport - ($min-viewport/100%)*($container-padding) - ($min-viewport/100) * 9;
$min-vw: $min-viewport;
//$max-vw: $max-viewport - ($max-viewport/100%)*($container-padding) - ($max-viewport/100) * 9;
$max-vw: $max-viewport;

table {

  //@include scut-rem-fallback(13);

  //@include rfs(13);
  @include fluid-type-rem($min-vw, $max-vw, $min-font-size, $max-font-size);

  //@include media('>small'){
  //  @include scut-rem-fallback(15);
  //}
  //
  //@include media('>large') {
  //  @include scut-rem-fallback(18);
  //}
}

tr {

  &:first-of-type{
    td:nth-of-type(3) {
      &::before {
        top: 0.5em;
      }
    }
  }

  &:last-of-type{
    td:nth-of-type(3) {
      &::before {
        height: .5em;
      }
    }
  }

  td {
    padding-bottom: 1em;
    position: relative;
    vertical-align: top;

    &:first-of-type {
      text-align: right;
      padding-right: 0.9em;
      color: $time-line-year-color;
      font-weight: $font-weight-normal;
      display: block;
      @include media('>small') {
        width: 8.2em;
        padding-right: 1em;
      }
    }

    &.dot {
      position: relative;
      width: $dot-width;
      padding: 0;
      z-index: 1;

      &::after {
        content: '';
        width: $dot-width;
        height: $dot-width;
        position: absolute;
        top: 0.4em;
        background-color: $time-line-dot-color;
        border-radius: 50%;
      }
    }

    &:nth-of-type(3) {
      color: $time-line-text-color;
      padding-left: 0.9em;
      letter-spacing: $letter-spacing;
      @include media('>small') {
        padding-left: 1em;
      }

      &::before {
        content: '';
        //margin-left: -0.65em;
        margin-left: -(1em - $border-width/2 - $dot-width);
        border-left: $border-width solid $time-line-line-color;
        position: absolute;
        //top: 0.5em;
        left: 0;
        height: 100%;
      }
    }
  }
}
