// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

@import "mixins/display-inline";
@import "mixins/clearfix";

/// Event wrapper
/// @author Harry Roberts
/// @param {Bool} $self [false] - Whether or not to include current selector
/// @link https://twitter.com/csswizardry/status/478938530342006784 Original tweet from Harry Roberts
@mixin on-event($self: false) {
  @if $self {
    &,
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  } @else {
    &:hover,
    &:active,
    &:focus {
      @content;
    }
  }
}

/// Make a context based selector a little more friendly
/// @author Hugo Giraudel
/// @param {String} $context
@mixin when-inside($context) {
  #{$context} & {
    @content;
  }
}


//TODO: wird das genutzt?
@mixin make-container() {
  width: 100%;
  padding-right: ($container-padding);
  padding-left: ($container-padding);
  margin-right: auto;
  margin-left: auto;
}

@function strip-unit($value) {
  @return $value / ($value * 0 + 1);
}

@mixin fluid-type($min-vw, $max-vw, $min-font-size, $max-font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  @if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

      font-size: $min-font-size;
      @media screen and (min-width: $breakpoint1) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
      }
      @media screen and (min-width: $breakpoint2) {
        font-size: $max-font-size;
      }

  }
}

@mixin fluid-type-rem($min-vw, $max-vw, $min-font-size, $max-font-size, $property: font-size) {
  $u1: unit($min-vw);
  $u2: unit($max-vw);
  $u3: unit($min-font-size);
  $u4: unit($max-font-size);

  //@if $u1 == $u2 and $u1 == $u3 and $u1 == $u4 {

  @include rem((#{$property}: $min-font-size));
  @media screen and (min-width: scut-em($min-vw)) {
    #{$property}: strip-unit($max-font-size/$min-font-size) * 8px;
    #{$property}: calc(#{rem($min-font-size)} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
  }
  @media screen and (min-width: scut-em($max-vw)) {
    @include rem((#{$property}: $max-font-size));
  }

  //}
}

@mixin linear-gradient($angle,$gradient1, $gradient2) {
  background-color: $gradient2; /* Fallback Color */
  background-image: linear-gradient($angle, $gradient1, $gradient2);
  filter: progid:DXImageTransform.Microsoft.gradient(GradientType=$angle,StartColorStr='#{$gradient1}', EndColorStr='#{$gradient2}');
}



/*!
Typeplate : Starter Kit
URL ........... http://typeplate.com
Version ....... 3.0.2
Github ........ https://github.com/typeplate/starter-kit
Authors ....... Dennis Gaebel (@gryghostvisuals) & Zachary Kain (@zakkain)
License ....... Creative Commmons Attribution 3.0
License URL ... https://github.com/typeplate/starter-kit/blob/master/license.txt
*/


// $Context Calculator
// -------------------------------------//
// target / context = result
//
// @example             p { font-size: typl8-context-calc(24, 16, px, 18 * 1.65); }

@function typl8-context-calc($scale, $base, $value) {
  @return ($scale/$base) + $value;
}

// $Blockquote
// -------------------------------------//
// "-" is your citation flourish.
//
// I always say important things because I'm so smart
//                           - Super Important Person
//
// @example                 .your-class-name { @include typl8-blockquote("-"); }

@mixin typl8-blockquote($citation-flourish) {
  p {
    &:last-of-type {
      margin-bottom: -#{$typl8-line-height/2}em;
    }
  }

  + figcaption {
    @extend %typl8-cite;

    &:before {
      content: $citation-flourish;
    }
  }
}

@mixin typl8-cite-style($display, $text-align, $font-size) {
  display: $display;
  font-size: $font-size;
  text-align: $text-align;
}

// $Pull-Quotes
// -------------------------------------//

@mixin typl8-pull-quotes($fontsize, $opacity, $color) {
  position: relative;
  padding: typl8-context-calc($fontsize, $fontsize, em);

  &:before,
  &:after {
    height: typl8-context-calc($fontsize, $fontsize, em);
    opacity: $opacity;
    position: absolute;
    font-size: $fontsize;
    color: $color;
  }

  &:before {
    content: '\u201c';
    top:  0;
    left: 0;
  }

  &:after {
    content: '\u201d';
    bottom: 0;
    right: 0;
  }
}

