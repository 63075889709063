#publication {
  background-color: $section-bg-color;

  h2 {
    display: block;
  }
  .container {
    position: relative;
    @include clearfix;
  }

  table {
    float: left;
    width: 57%;
  }

  .img-wrapper {
    float: left;
    width: 24%;
    margin-left: 0;

    img {
      width: 267%;
      margin-right: -15%;
      margin-top: -60%;

    }
    @include media('<small') {
      width: 100%;

      img {
        width: 100%;
        margin: 0;
      }
    }
  }
}


